<template>
  <v-timeline-item fill-dot>
    <template v-slot:icon>
      <v-tooltip bottom v-if="comment.external_reference">
        <template v-slot:activator="{ on, attrs }"> 
          <div
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              bordered
              color="success"
              icon="mdi-wifi-star"
              overlap
            >
              <user-avatar
                :avatar_url="comment.user.avatar_url"
                :user_name="comment.user.name"
                v-if="comment.user && comment.user.avatar_url"
              />
              <v-avatar
                color="secondary" 
                v-else 
                dark
              >
                <v-icon dark> mdi-shield-account </v-icon>
              </v-avatar>
            </v-badge>
          </div>
        </template>
        <span>{{ $capitalize($tc('model.comments_submitted_by_integration')) }}</span>
      </v-tooltip>
      <div v-else>
        <user-avatar
          :avatar_url="comment.user.avatar_url"
          :user_name="comment.user.name"
          v-if="comment.user && comment.user.avatar_url"
        />
        <v-avatar
          color="secondary" 
          v-else 
          dark
        >
          <v-icon dark> mdi-shield-account </v-icon>
        </v-avatar>
      </div>
    </template>
    <v-sheet>
      <v-list-item :class="{ 'info--text': comment.has_notifications_to_manager }">
        <v-list-item-content>
          <v-list-item-title>
            {{ comment.user ? comment.user.name : comment.created_by }}
            <v-menu v-if="hasActions">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  :loading="sending_action"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-if="allowSendCompanyInfo">
                  <v-subheader>{{ $capitalize($tc('model.comments_action_send_info_company')) }}</v-subheader>
                  <ScopeProvider scope="info_requests.open">
                    <v-list-item 
                      @click="openMotiveDialog('request')" 
                      :disabled="disabledOpening || isClosingComment || comment.has_request_info_notification"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-comment-question-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $capitalize($tc('model.comments_open_info_request')) }}</v-list-item-title>
                        <v-list-item-subtitle v-if="disabledOpening && !isClosingComment">
                          {{ $capitalize($tc('model.comments_reply_close_info_message')) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="comment.has_request_info_notification">
                          {{ $capitalize($tc('model.comments_send_request_in_queue_message')) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="isClosingComment && !comment.has_request_info_notification">
                          {{ $capitalize($tc('model.comments_not_open_comment_message')) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </ScopeProvider>
                  <ScopeProvider scope="info_requests.close">
                    <v-list-item 
                      @click="openMotiveDialog('send')" 
                      :disabled="disableClosing || comment.has_send_info_notification"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-keyboard-return</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $capitalize($tc('model.comments_close_info_request')) }}</v-list-item-title>
                        <v-list-item-subtitle v-if="disableClosing">
                          {{ $capitalize($tc('model.comments_no_open_info_request')) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="comment.has_send_info_notification">
                          {{ $capitalize($tc('model.comments_send_request_in_queue_message')) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </ScopeProvider>
                </template>
                <template v-if="allowManualActions">
                  <v-subheader>{{ $capitalize($tc('model.comments_any_action_company')) }}</v-subheader>
                  <ScopeProvider scope="info_requests.close_no_action">
                    <v-list-item @click="confirmCloseInfoRequest" :disabled="info_request.closed_at != null">
                      <v-list-item-icon>
                        <v-icon>mdi-lock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $capitalize($tc('model.comments_mark_closed_list_item')) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </ScopeProvider>
                  <ScopeProvider scope="info_requests.reopen_no_action">
                    <v-list-item @click="confirmReopenInfoRequest" :disabled="info_request.closed_at == null">
                      <v-list-item-icon>
                        <v-icon>mdi-lock-open</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $capitalize($tc('model.comments_mark_open_list_item')) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </ScopeProvider>
                </template>
              </v-list>
            </v-menu>
          </v-list-item-title>
          <v-list-item-subtitle :class="{ 'info--text': comment.has_notifications_to_manager }">
            <span v-if="comment.created_on">
              {{ formatDateTimeWithDuration(comment.created_on) }}
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-1">
            <v-chip
              label
              v-if="comment.info_request" 
              color="orange" dark small
            >
              {{ $capitalize($tc('model.comments_information_request_subtitle')) }}
            </v-chip>
            <v-chip
              label
              v-if="last_info_request.closing_comment_id == comment.id" 
              small
            >
              {{ $capitalize($tc('model.comments_close_info_request')) }}
            </v-chip>
            <v-chip class="ml-1" v-if="comment.info_request && comment.info_request.closed_at" color="success" dark small> {{ $capitalize($tc('model.comments_closed_chip')) }} </v-chip>
            <v-tooltip bottom v-if="comment.info_request">
              <template v-slot:activator="{ on, attrs }"> 
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="info_request_response_integration.status"
                  class="mx-1"
                  dense
                >{{ info_request_response_integration.icon }}</v-icon>
              </template>
              <span>{{ info_request_response_integration.text }}</span>
            </v-tooltip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text class="text-justify comment font-weight-medium py-0" :class="{ 'info--text': comment.has_notifications_to_manager }">{{
        comment.title || $capitalize($tc('model.comments_comment_untitled'))
      }}</v-card-text>
      <v-card-text class="text-justify comment pt-1" v-html="nlToBr(comment.description)" :class="{ 'info--text': comment.has_notifications_to_manager }" />
      <v-card-text class="py-0">
        <a 
          class="link" 
          @click="toggleReplies" 
          v-if="comment.replies.length > 0"
        >
          {{ seeReplyText(comment) }}
        </a>
      </v-card-text>
    </v-sheet>
    <Replies :comment="comment" v-if="open_replies" />
    <RequestInfoMotiveDialog
      v-model="request_info_dialog"
      @submit="openOrClose(true, $event)"
    />    
    <SendInfoMotiveDialog
      v-model="send_info_dialog"
      @submit="openOrClose(false, $event)"
    />  
  </v-timeline-item>
</template>

<script>
import { mapActions } from "vuex";
import { formatDateTime, formatDateTimeWithDuration } from "@/tools/date";
import { nlToBr } from "@/tools/string";
import { 
  close as closeInfoRequest, 
  reopen as reopenInfoRequest, 
  getInboundNotificationStatus,
  sendAndCloseInfoRequest,
  openInfoRequest
} from "@/services/info_requests";

import ScopeProvider from "@/components/ScopeProvider";
import UserAvatar from "@/components/UserAvatar";
import Replies from "@/components/Lead/Comment/Replies";
import RequestInfoMotiveDialog from "@/components/Lead/Comment/RequestInfoMotiveDialog";
import SendInfoMotiveDialog from "@/components/Lead/Comment/SendInfoMotiveDialog";

export default {
  name: "comment",
  components: { UserAvatar, Replies, RequestInfoMotiveDialog, SendInfoMotiveDialog, ScopeProvider },
  props: ["comment", "last_comment"],
  data() {
    return {
      open_replies: false,
      closing: false,
      reopening: false,

      sending_action: false,
      request_info_dialog: false,
      send_info_dialog: false,
    };
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    last_info_request() {
      return this.lead.last_info_request || {};
    },
    info_request_response_integration() {
      const inbound_notification = this.comment?.info_request?.inbound_notification;

      return getInboundNotificationStatus(inbound_notification);
    },
    info_request() {
      return this.comment.info_request || {};
    },
    allowSendCompanyInfo() {
      return this.comment.id == this.last_comment.id && 
              this.last_comment.info_request?.id == null && 
              this.last_info_request.id != null &&
              (this.$store.getters["auth/getScope"]("info_requests.close") ||
              this.$store.getters["auth/getScope"]("info_requests.open"));
    },
    allowManualActions() {
      return this.info_request.id != null &&
              (this.$store.getters["auth/getScope"]("info_requests.close_no_action") ||
              this.$store.getters["auth/getScope"]("info_requests.reopen_no_action"))
    },
    hasActions() {
      return this.allowManualActions || 
            this.allowSendCompanyInfo;
    },
    disableClosing() {
      return this.last_info_request.closed_at != null;
    },
    disabledOpening() {
      return this.last_info_request.closed_at == null;
    },
    isClosingComment() {
      return this.last_info_request.closing_comment_id == this.comment.id
    }
  },
  methods: {
    confirmReopenInfoRequest() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.$capitalize(this.$tc('model.comments_reopen_info_request')),
        description: this.$capitalize(this.$tc('model.comments_order_reopen_no_action_message')),
        positive_action: () => this.reopenInfoRequest(),
      }); 
    },
    async reopenInfoRequest() {
      this.reopening = true;
      try {
        const response = await reopenInfoRequest(this.comment?.info_request?.id);
        this.$listeners.updateComment({
          ...this.comment,
          info_request: response
        });

        if (response.is_last_from_lead) {
          this.updateLeadsLastInfoRequest(response);
        }
        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_info_request_reopened')),
          color: "green",
        });
        this.loadMyInfoRequests();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_failed_reopen_info_request_alert')),
          color: "red",
        });
      } finally {
        this.reopening = false;
      }
    },
    confirmCloseInfoRequest() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.$capitalize(this.$tc('model.comments_close_info_request')) + '?',
        description: this.$capitalize(this.$tc('model.comments_order_closed_no_action_message')),
        positive_action: () => this.closeInfoRequest(),
      }); 
    },
    async closeInfoRequest() {
      this.closing = true;
      try {
        const response = await closeInfoRequest(this.comment?.info_request?.id);
        this.$listeners.updateComment({
          ...this.comment,
          info_request: response
        });

        if (response.is_last_from_lead) {
          this.updateLeadsLastInfoRequest(response);
        }
        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_info_request_closed')),
          color: "green",
        });
        this.loadMyInfoRequests();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_fail_closed_info_request_alert')),
          color: "red",
        });
      } finally {
        this.closing = false;
      }
    },

    openMotiveDialog(type_of_info) {
      if (type_of_info == 'request') {
        this.request_info_dialog = true;
      } else {
        this.send_info_dialog = true;
      }
    },
    async openOrClose(request = false, motive = {}) {
      this.sending_action = true;
      try {
        if (request) {
          await this.openInfoRequest(motive);
        } else {
          await this.sendAndClose(motive);
        }
      } catch (error) {
        if (error?.response?.status == 422) {
          this.$store.commit("sendMessage", {
            text: error?.response?.data?.message,
            color: "red",
          });
        } else {
          this.$store.commit("sendMessage", {
            text: "Falha ao enviar ação",
            color: "red",
          });
        }
      } finally {
        this.sending_action = false;
      }
    },
    async sendAndClose(payload) {
      const response = await sendAndCloseInfoRequest(this.$route.params["lead_number"], this.comment?.id, payload);
      this.$listeners.refreshComments(response.comments);
      this.updateLeadsLastInfoRequest(response.last_info_request);
      this.$store.commit("sendMessage", {
        text: "Ação de fechamento de pedido de informação criada.",
        color: "green",
      });
      return response.comments;
    },
    async openInfoRequest(payload) {
      const response = await openInfoRequest(this.$route.params["lead_number"], payload);
      this.$store.commit("sendMessage", {
        text: "Ação de pedido de informação criada.",
        color: "green",
      });
      return response;
    },
    updateLeadsLastInfoRequest(info_request) {
      this.$store.commit('setLead', {
        ...this.lead,
        last_info_request: info_request
      });
    },

    // Reply functions
    toggleReplies() {
      this.open_replies = !this.open_replies;
    },
    seeReplyText(comment) {
      let anwser_text = 'resposta';

      if (comment.replies.length > 1) {
        answer_text = 'respostas';
      }

      return this.open_replies ? 
        `Esconder ${anwser_text}` : 
        `Ver ${comment.replies.length} ${anwser_text}`;
    },

    // Util functions
    nlToBr(text) {
      return nlToBr(text);
    },
    formatLongDate(date) {
      return formatDateTime(date);
    },
    formatDateTimeWithDuration(date) {
      return formatDateTimeWithDuration(date);
    },
    ...mapActions("info_requests", ["loadMyInfoRequests"]),
  }
}
</script>

<style>
</style>