import Vue from "vue";

export async function index(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/comments`
  );

  return response.data;
}

export async function store(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/comment`,
    payload
  );

  return response.data;
}

export async function storeAndCloseInfoRequest(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/comment/close_info_request`,
    payload
  );

  return response.data;
}

export async function storeAndOpenInfoRequest(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/comment/open_info_request`,
    payload
  );

  return response.data;
}

export async function storeAndSendInformation(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/comment/send_information`,
    payload
  );

  return response.data;
}

export async function closeInfoRequest(lead_number, payload) {
  const response = await Vue.prototype.$http.put(
    `leads/${lead_number}/comment/open_info_request`,
    payload
  );

  return response.data;
}

export async function openInfoRequest(lead_number, payload) {
  const response = await Vue.prototype.$http.put(
    `leads/${lead_number}/comment/open_info_request`,
    payload
  );

  return response.data;
}