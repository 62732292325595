<style lang="scss" scoped>
#comment_container {
  // max-height: 600px;
  overflow-y: auto;
  border: 2px #333;
  padding: 0;
  margin: 0;
  background-color: #f1f1f1;
}
.headline {
  font-size: 20px !important;
}
.comment {
  color: black !important;
}
</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined :loading="loading">
        <v-card-title>
          {{ $capitalize($tc("model.comments_company_page_title")) }}
        </v-card-title>
        <v-card-text>
          <v-btn
            @click="see_last_comments = true"
            text
            color="primary"
            v-if="see_last_comments == false && comments.length > 3"
          >
            {{ $capitalize($tc("model.comments_previous_comments")) }} ({{
              comments.length - 3
            }})
          </v-btn>
          <v-btn
            @click="see_last_comments = false"
            text
            color="primary"
            v-else-if="see_last_comments == true"
          >
            {{ $capitalize($tc("model.comments_hide_old_comments")) }}
          </v-btn>
        </v-card-text>
        <v-timeline
          dense
          clipped
          align-top
        >
          <template v-for="comment in filtered_comments">
            <Comment 
              :comment="comment"
              :last_comment="last_comment"
              :key="comment.id" 
              @updateComment="updateComment"
              @refreshComments="refreshComments"
            />
          </template>
          <NewComment @refreshComments="refreshComments" />
        </v-timeline>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatDateTime } from "@/tools/date";
import { index as indexComments } from "@/services/comments";

import Comment from "@/components/Lead/Comment/Comment";
import NewComment from "@/components/Lead/Comment/NewComment";


export default {
  name: "comments-tab",
  components: { Comment, NewComment },
  data: () => ({
    comments: [],
    loading: false,
    see_last_comments: false,
  }),
  methods: {
    formatLongDate(date) {
      return formatDateTime(date);
    },
    updateComment(comment) {
      this.comments = this.comments.map(rec => {
        if (rec.id == comment.id) {
          return comment;
        }

        return rec;
      });
    },
    async getComments() {
      this.comments = [];
      this.loading = true;
      try {
        const response = await indexComments(this.$route.params["lead_number"]);
        this.comments = response;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Falha ao consultar comentários",
          color: "red",
        });
      } finally {
        this.loading = false;
      }
    },

    refreshComments(new_comments) {
      this.comments = new_comments;
    }
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
    filtered_comments: function () {
      return this.comments.filter((comment, index) => {
        if (this.see_last_comments) {
          return true;
        } else {
          return index > this.comments.length - 4;
        }
      });
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },
    last_comment() {
      return this.comments[this.comments.length-1] || {};
    },
  },
  created() {
    this.getComments();
  },
};
</script>