<template>
  <v-list class="mt-3">
    <template v-for="reply in comment.replies">
      <v-list-item :key="'user_' + reply.id">
        <v-list-item-avatar>
          <user-avatar
            :avatar_url="reply.user.avatar_url"
            :user_name="reply.user.name"
            v-if="reply.user && reply.user.avatar_url"
            size="30"
          />
          <v-avatar color="secondary" v-else dark size="30">
            <v-icon dark> mdi-shield-account </v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ reply.user ? reply.user.name : reply.created_by }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="reply.created_on">{{
              formatDateDuration(reply.created_on)
            }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :key="'reply_' + reply.id">
        <v-list-item-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="py-0">
          <v-list-item-title class="text-wrap">
            <v-card-text :key="reply.id" class="text-justify comment font-weight-medium px-0 py-0">{{
              reply.title || $capitalize($tc('model.comments_comment_untitled'))
            }}</v-card-text>
            <v-card-text :key="reply.id" class="text-justify comment pt-1 px-0" v-html="nlToBr(reply.description)" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { formatDateDuration } from "@/tools/date";
import { nlToBr } from "@/tools/string";
import UserAvatar from "@/components/UserAvatar";
export default {
  name: 'replies',
  components: { UserAvatar },
  props: ["comment"],
  methods: {
    // Util functions
    nlToBr(text) {
      return nlToBr(text);
    },
    formatDateDuration(date) {
      return formatDateDuration(date);
    }
  }
}
</script>

<style>

</style>