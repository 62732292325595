<template>
  <v-timeline-item fill-dot>
    <template v-slot:icon>
      <user-avatar
        :user_name="user.name"
        :avatar_url="user.avatar"
      />
    </template>
    <ScopeProvider scope="leads.comments.add">
      <v-form>
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <v-card-title id="comment-send_feedback" class="pt-0">{{
            $capitalize($tc("model.comments_send_feedback"))
          }}</v-card-title>
          <v-card-text>
            <ValidationProvider
              name="title"
              rules="required|max:256"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.title"
                name="title"
                :error-messages="errors"
                filled
                :counter="256"
                :label="$capitalize($tc('model.comments_comment_title'))"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="description"
              rules="required|max:512"
              v-slot="{ errors }"
            >
              <v-textarea
                v-model="form.description"
                name="description"
                :error-messages="errors"
                :counter="512"
                :label="$capitalize($tc('model.comments_comment_description'))"
                filled
              ></v-textarea>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-bottom-sheet v-model="sheet" inset>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  :loading="sending_message"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="invalid"
                >
                  <v-icon size="17" class="mr-1">mdi-send</v-icon>
                  {{ $capitalize($tc('model.comments_comment_button')) }}
                </v-btn>
              </template>
              <v-list>
                <v-subheader>{{ $capitalize($tc('model.comments_available_actions')) }}</v-subheader>
                <ScopeProvider scope="info_requests.close">
                  <v-list-item 
                    @click="openMotiveDialog('send', 'close_info_request')"
                    v-if="last_info_request.id != null && last_info_request.closed_at == null"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        size="32px"
                        tile
                      >
                        <v-icon>mdi-keyboard-return</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $capitalize($tc('model.comments_close_info_request')) }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $capitalize($tc('model.comments_send_info_company')) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </ScopeProvider>
                <ScopeProvider scope="info_requests.open">
                  <v-list-item
                    @click="openMotiveDialog('request', 'open_info_request')"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        size="32px"
                        tile
                      >
                        <v-icon>mdi-comment-question-outline</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $capitalize($tc('model.comments_open_info_request')) }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $capitalize($tc('model.comments_ask_info_company')) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </ScopeProvider>
                <ScopeProvider scope="leads.comments.add">
                  <v-list-item
                    @click="openMotiveDialog('send', 'send_info_only')"
                    v-if="last_info_request.id == null || (last_info_request.id != null && last_info_request.closed_at != null)"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        size="32px"
                        tile
                      >
                        <v-icon>mdi-send</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $capitalize($tc('model.comments_send_information_company')) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="commentOnly">
                    <v-list-item-avatar>
                      <v-avatar
                        size="32px"
                        tile
                      >
                        <v-icon>mdi-text</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $capitalize($tc('model.comments_comment_without_sending_action')) }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $capitalize($tc('model.comments_send_comment_without_generating_action')) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item 
                    @click="sheet = false"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        size="32px"
                        tile
                      >
                        <v-icon color="red">mdi-window-close</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-title> {{ __('Cancelar', 'leads') }} </v-list-item-title>
                  </v-list-item>
                </ScopeProvider>
              </v-list>
            </v-bottom-sheet>
            <v-btn @click="resetForm" text>{{
              $capitalize($tc("model.comments_clean_button"))
            }}</v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-form>
    </ScopeProvider>
    <RequestInfoMotiveDialog
      v-model="request_info_dialog"
      @submit="sendInfo(request_info_type, $event)"
    />    
    <SendInfoMotiveDialog
      v-model="send_info_dialog"
      @submit="sendInfo(send_info_type, $event)"
    />  
  </v-timeline-item>
</template>

<script>
import { storeAndCloseInfoRequest, storeAndOpenInfoRequest, storeAndSendInformation, store } from "@/services/comments";

import ScopeProvider from "@/components/ScopeProvider";
import UserAvatar from "@/components/UserAvatar";
import RequestInfoMotiveDialog from "@/components/Lead/Comment/RequestInfoMotiveDialog";
import SendInfoMotiveDialog from "@/components/Lead/Comment/SendInfoMotiveDialog";
import i18n from '@/mixins/i18n';

export default {
  name: 'new-comment',
  mixins: [i18n],
  components: { ScopeProvider, UserAvatar, RequestInfoMotiveDialog, SendInfoMotiveDialog },
  data() {
    return {
      form: {},
      sheet: false,
      sending_message: false,

      request_info_dialog: false,
      request_info_type: null,

      send_info_dialog: false,
      send_info_type: null,
    };
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
    last_info_request() {
      return this.lead.last_info_request || {};
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },
  methods: {
    async commentOnly() {
      if(!confirm(this.$capitalize(this.$tc('model.comments_send_comment_without_action_confirm')))) return;

      const validation = await this.$refs.form.validate();
      if(!validation) return;

      this.loading = true;
      this.sending_message = true;

      try {
        const response = await this.sendCommentOnly({...this.form});
        this.$listeners.refreshComments(response);
        this.form = {};
        this.$refs.form.reset();
      } catch(error) {
        if (error?.response?.status == 422) {
            this.$store.commit("sendMessage", {
              text: error.response.data.message,
              color: "red",
            });
          } else {
            this.$store.commit("sendMessage", {
              text: this.$capitalize(this.$tc('model.comments_failed_send_comment')),
              color: "red",
            });
          }
      } finally {
        this.loading = false;
        this.sending_message = false;
        this.sheet = false;
      }
    },
    openMotiveDialog(type_communication, type_of_info) {
      if (type_communication == 'request') {
        this.request_info_dialog = true;
        this.request_info_type = type_of_info;
      } else if (type_communication == 'send') {
        this.send_info_dialog = true;
        this.send_info_type = type_of_info;
      }
    },
    async sendInfo(type_of_info, motive = {}) {
      const payload = {
        ...this.form,
        ...motive
      };

      const validation = await this.$refs.form.validate();

      if (validation) {
        this.loading = true;
        this.sending_message = true;
        try {
          let response = null;
          switch(type_of_info) {
            case 'close_info_request':
              response = await this.sendAndCloseInfoRequest(payload);
              break;
            case 'open_info_request':
              response = await this.sendAndOpenInfoRequest(payload);
              break;
            case 'send_info_only':
              response = await this.sendInformationOnly(payload);
              break;
          }
          this.$listeners.refreshComments(response);
          this.form = {};
          this.$refs.form.reset();
        } catch (error) {
          if (error?.response?.status == 422) {
            this.$store.commit("sendMessage", {
              text: error.response.data.message,
              color: "red",
            });
          } else {
            this.$store.commit("sendMessage", {
              text: this.$capitalize(this.$tc('model.comments_failed_send_comment')),
              color: "red",
            });
          }
        } finally {
          this.loading = false;
          this.sending_message = false;
          this.sheet = false;
        }
      }
    },
    async sendInformationOnly(payload) {
      const response = await storeAndSendInformation(this.$route.params["lead_number"], payload);
      this.$store.commit("sendMessage", {
        text: this.$capitalize(this.$tc('model.comments_comment_sent')),
        color: "green",
      });
      return response.comments;
    },
    async sendAndCloseInfoRequest(payload) {
      const response = await storeAndCloseInfoRequest(this.$route.params["lead_number"], payload);
      this.updateLeadsLastInfoRequest(response.last_info_request);
      this.$store.commit("sendMessage", {
        text: this.$capitalize(this.$tc('model.comments_comment_sent_information_request_closed')),
        color: "green",
      });
      return response.comments;
    },
    async sendAndOpenInfoRequest(payload) {
      const response = await storeAndOpenInfoRequest(this.$route.params["lead_number"], payload);
      this.$store.commit("sendMessage", {
        text: this.$capitalize(this.$tc('model.commentscomment_sent_information_request_open')),
        color: "green",
      });
      return response;
    },
    async sendCommentOnly(payload) {
      const response = await store(this.$route.params["lead_number"], payload);
      this.$store.commit("sendMessage", {
        text: this.$capitalize(this.$tc('model.comments_comment_sent_remember_send_action')),
        color: "green",
      });
      return response;
    },
    updateLeadsLastInfoRequest(info_request) {
      this.$store.commit('setLead', {
        ...this.lead,
        last_info_request: info_request
      });
    },
    resetForm() {
      this.form = {};
      this.$refs.form.reset();
    },
  }
}
</script>

<style>

</style>