<template>
  <v-dialog
    :value="value"
    @change="handleChange"
    @click:outside="handleClose"
    max-width="800px"
  >
    <v-card>
      <v-app-bar flat>
        <v-card-title class="px-0">{{
          $capitalize($tc("model.comments_reason_info_request_title"))
        }}</v-card-title>

        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <ValidationObserver ref="info_request_motive_form">
        <v-form @submit.prevent="handleSubmit">
          <v-card-text>
            <v-alert dense type="error" v-if="lead_state && !isActionValid"
              >{{
                $capitalize($tc("model.comments_cannot_open_info_message"))
              }}
              {{ lead_state.state.name }}</v-alert
            >
            <ValidationProvider
              name="action"
              rules="required"
              v-slot="{ errors }"
            >
              <v-radio-group
                name="action"
                v-model="form.motive"
                :label="$capitalize($tc('model.integration_reason_label'))"
                :error-messages="errors"
              >
                <v-radio
                  v-for="motive in motives"
                  :key="motive.external_code"
                  :label="motive.name"
                  :value="motive"
                ></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="justification"
              :rules="
                (form.motive && form.motive.needs_justification
                  ? 'required|'
                  : '') + 'max:255'
              "
              v-slot="{ errors }"
            >
              <v-textarea
                :label="
                  $capitalize($tc('model.integration_justification_label'))
                "
                v-model="form.justification"
                outlined
                :error-messages="errors"
                :counter="255"
                name="justification"
              ></v-textarea>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="!isActionValid" type="submit" color="primary">{{
              $capitalize($tc("model.comments_confirm_button"))
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      form: {
        motive: {},
      },
    };
  },
  computed: {
    lead_type() {
      return this.$store.getters.getLead?.lead_type;
    },
    lead_state: function () {
      return this.$store.getters.getLead?.default_service?.external_status;
    },
    action() {
      if (this.lead_type) {
        return this.$store.getters["eps/getActions"].find(
          (action) => action.canonical == "eps_request_information_examination"
        )?.external_actions[this.lead_type];
      }

      return null;
    },
    motives() {
      if (this.action) {
        const motives = this.action ? this.action.motives : [];

        return motives;
      }

      return [];
    },
    isActionValid() {
      return true;

      return (this.action?.states || []).some(
        (state) => state.external_code == this.lead_state?.external_code
      );
    },
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
      this.form = {};
      this.$refs.info_request_motive_form.reset();
    },
    async handleSubmit() {
      const validation = await this.$refs.info_request_motive_form.validate();
      if (!validation) return;
      this.$listeners.submit({
        ...this.form,
        motive: this.form.motive.external_code,
      });
      this.handleClose();
    },
  },
};
</script>

<style></style>
